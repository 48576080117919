import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'

const TagRanked = ({ text, margin, backgroundColor }) => {
  return (
    <Wrapper margin={margin} backgroundColor={backgroundColor}>
      <p>{text}</p>
    </Wrapper>
  )
}

export default TagRanked

const Wrapper = styled.div`
  margin: ${(props) => props.margin || '0'};
  background: ${(props) => props.backgroundColor || color.blueBackground};
  padding: 8px 12px;
  border-radius: 10px;
  color: ${color.blue};
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  @media screen and (min-width: ${breakpoint.xl}) {
    text-align: left;
  }
`
